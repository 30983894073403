import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  ItemDetailType,
  ItemProps,
  ItemsType,
  LastSalesProps,
  LastSalesType,
  OwnersProps,
  OwnersType,
} from '.';
import { URLS } from '../../api';
import { axiosBaseQuery } from '../../helpers';
import { ISelectOption } from '../../components/FormItems/CustomSelect/type';

export const itemsAPI = createApi({
  reducerPath: 'itemsAPI',
  tagTypes: ['Items', 'Last_sales', 'PropertyType', 'Currencies'],
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    createItem: build.mutation({
      query: ({ formData }) => ({
        url: URLS.items,
        method: 'post',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Items'],
    }),

    editItem: build.mutation({
      query: ({ formData, id }) => ({
        url: `${URLS.items}/${id}`,
        method: 'put',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Items'],
    }),

    deleteItem: build.mutation({
      query: ({ id }) => ({
        url: `${URLS.items}/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => ['Items'],
    }),

    burnItem: build.mutation({
      query: ({ value, id }) => ({
        url: `${URLS.items}/${id}/burn`,
        method: 'post',
        data: value,
      }),
      invalidatesTags: () => ['Items'],
    }),

    getItems: build.query<ItemsType, ItemProps>({
      query: ({ page, perPage, url }) => ({
        url: !url
          ? `${URLS.items}?page_number=${page}&page_size=${perPage}&purpose=store`
          : `${URLS.items}?page_number=${page}&page_size=${perPage}&purpose=store&${url}`,
        method: 'get',
      }),
      providesTags: () => ['Items'],
    }),

    getItemsById: build.query<ItemDetailType, { id: string }>({
      query: ({ id }) => ({
        url: `${URLS.items}/${id}`,
        method: 'get',
      }),
      providesTags: () => ['Items'],
    }),
    getPropertyType: build.query<{ data: ISelectOption[] }, unknown>({
      query: () => ({
        url: `${URLS.items}/property_types`,
        method: 'get',
      }),
      providesTags: () => ['PropertyType'],
    }),
    getCurrencies: build.query<{ data: { [ket in string]: number } }, unknown>({
      query: () => ({
        url: `${URLS.currencies}`,
        method: 'get',
      }),
      providesTags: () => ['Currencies'],
    }),

    getItemLastSales: build.query<LastSalesType, LastSalesProps>({
      query: ({ id, page, perPage }) => ({
        url: `${URLS.items}/${id}/last_sales?page_number=${page}&page_size=${perPage}`,
        method: 'get',
      }),
    }),

    getItemOwners: build.query<OwnersType, OwnersProps>({
      query: ({ id, page, perPage, sortObject }) => {
        const sort =
          sortObject.sortField && sortObject.sortType
            ? `&${sortObject.sortField}=${sortObject.sortType}`
            : '';

        return {
          url: `${URLS.items}/${id}/owners?page_number=${page}&page_size=${perPage}${sort}`,
          method: 'get',
        };
      },
    }),

    postGiveaWay: build.mutation({
      query: ({ data, id }) => ({
        url: `${URLS.giveaway.replace('<id>', id)}`,
        method: 'post',
        data,
      }),
      invalidatesTags: () => ['Last_sales'],
    }),
  }),
});

export const {
  useGetItemsByIdQuery,
  useGetItemLastSalesQuery,
  useCreateItemMutation,
  useEditItemMutation,
  useDeleteItemMutation,
  useGetItemsQuery,
  useGetItemOwnersQuery,
  usePostGiveaWayMutation,
  useBurnItemMutation,
  useGetPropertyTypeQuery,
  useGetCurrenciesQuery,
} = itemsAPI;
